import React, { createContext, useState, useContext, useEffect } from 'react';

// Создание контекста
const AnimeContext = createContext();

// Функция для определения языка на основе страны
const determineLanguage = (country) => {
  const uaCountries = ['UA'];
  const ruCountries = ['RU', 'KZ', 'BY', 'MD', 'KG', 'TJ', 'TM', 'UZ'];

  if (uaCountries.includes(country)) return 'ua';
  if (ruCountries.includes(country)) return 'ru';
  return 'en'; // По умолчанию английский
};

// Провайдер контекста
export const AnimeProvider = ({ children }) => {
  const [anime, setAnime] = useState(null);
  const [lastWatchedAnime, setLastWatchedAnime] = useState(null);
  const [language, setLanguage] = useState('ua');

  useEffect(() => {
    const storedAnime = localStorage.getItem('lastWatchedAnime');
    if (storedAnime) {
      setLastWatchedAnime(JSON.parse(storedAnime));
    }


    const userCountry = navigator.language || navigator.userLanguage;
    const countryCode = userCountry.split('-')[1] || userCountry;
    const initialLanguage = determineLanguage(countryCode);
    setLanguage(initialLanguage);
  }, []);


  const updateLastWatchedAnime = (anime) => {
    if (anime) {
      setLastWatchedAnime(anime);
      localStorage.setItem('lastWatchedAnime', JSON.stringify(anime));
      console.log("Сохранено аниме: ", anime); // Проверка, что данные сохранены
    }
  };
  

  return (
    <AnimeContext.Provider value={{ anime, setAnime, lastWatchedAnime, updateLastWatchedAnime, language, setLanguage }}>
      {children}
    </AnimeContext.Provider>
  );
};

// Хук для использования контекста
export const useAnime = () => useContext(AnimeContext);
