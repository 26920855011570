import React, { useState, useEffect } from 'react';
import { request, gql } from 'graphql-request';
import { Link } from 'react-router-dom';
import tv from "../../../img/tv.svg";
import tv_hover from "../../../img/tv_hover.svg";
import '../../../style.css';

const endpoint = 'https://shikimori.one/api/graphql';
const kodikApiKey = '6c29a4ee6eb1f841b920f0ff8814ef24';

// Функция для очистки описания
const cleanDescription = (description) => {
  if (!description) return '';
  return description.replace(/\[.*?\]|[\p{Script=Han}]/gu, '');
};

// Функция для получения описания из Kodik
const fetchKodikDescription = async (shikimoriId) => {
  try {
    const response = await fetch(`https://kodikapi.com/search?token=${kodikApiKey}&shikimori_id=${encodeURIComponent(shikimoriId)}&with_episodes=true&with_material_data=true`);
    const data = await response.json();
    const video = data.results.find(result => result.material_data && result.material_data.description);
    return video ? video.material_data.description || '' : '';
  } catch (error) {
    console.error('Error fetching Kodik description:', error);
    return '';
  }
};

const Slider = () => {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const fetchData = async () => {
    const query = gql`
      {
        animes(limit: 10, season: "winter_2025", order: ranked, kind: "tv") {
          id
          russian
          description
          poster {
            originalUrl
          }
          releasedOn {
            year
          }
          screenshots {
            originalUrl
          }
          updatedAt
          createdAt
        }
      }
    `;

    try {
      const data = await request(endpoint, query);
      // Фильтруем аниме без видео
      const slidesWithDescriptions = await Promise.all(data.animes.map(async (anime) => {
        let description = cleanDescription(anime.description);
        if (!description) {
          description = await fetchKodikDescription(anime.id);
        }

        // Проверка, есть ли видео
        const hasVideo = description !== ''; // Если описание получено, значит, видео есть
        return hasVideo ? { ...anime, description } : null;
      }));

      // Фильтруем null из массива (аниме без видео)
      const validSlides = slidesWithDescriptions.filter(slide => slide !== null);
      setSlides(validSlides);
    } catch (error) {
      console.error('Ошибка при запросе аниме:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <div className="slider">
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
        >
          <img
            src={slide.poster.originalUrl}
            alt={slide.russian}
            loading="lazy"
            className="slide-image"
          />
          <div className="gradient-overlay-right"></div>
          <div className="slide-content">
            <h2>{slide.russian}</h2>
            <p>{cleanDescription(slide.description) || 'Нет описания'}</p>
            <div className="slide-buttons">
              <Link to={`/anime/${slide.id}`} className="watch-now">
                Watch Now
                <div className='wrap-img-btn'>
                  <img className="tv" src={tv} alt="Button Watch Now" />
                  <img className='tv_hover' src={tv_hover} alt="Button Watch Now Hover" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      ))}
      <CustomArrow direction="prev" onClick={handlePrev} />
      <CustomArrow direction="next" onClick={handleNext} />
    </div>
  );
};

const CustomArrow = ({ direction, onClick }) => (
  <div
    className={`custom-arrow custom-arrow-${direction}`}
    onClick={onClick}
  >
    <span className={`arrow-${direction}`} />
  </div>
);

export default Slider;
