import { Link } from "react-router-dom";
import { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";

const Login = () =>{

        const [email,setEmail] = useState("");
        const [password,setPassword] = useState("");
        const [error, setError] = useState("");

        function logIn(e){
            e.preventDefault()

            signInWithEmailAndPassword(auth, email, password )
            .then((user) => {
                setError("")
                setEmail("")
                setPassword("")
            }).catch((error) =>{
                console.log(error)
                setError("Простите,ваш аккаунт не найден")
            });
        }

    return(
        <div>
            <div className='s1 center-block'>
            <h2>Войти</h2>
            <form className="wrap-form">
                <label>
                <span className="wrap-text-label">Ваш E-Mail <span className="required">*</span>:</span>
                <input placeholder="Введите ваш Email" value={email} onChange={(e) => setEmail(e.target.value)} type="email"/>
                </label>
                <label>
                <span className="wrap-text-label">Ваш Пароль <span className="required">*</span>:</span>
                <input placeholder="Введите Пароль" value={password} onChange={(e) => setPassword(e.target.value)} type="password"/>
                </label>
                
                <button className="watch-now center" onClick={logIn}>Вход</button>
                {error ? <p style={{color:"red"}}>{error}</p> : ""}
            </form>

            <p><Link to="/sign-up">Регистрация</Link></p>
            </div>
        </div>
    )
}

export default Login;