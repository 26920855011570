import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AnimeProvider } from './AnimeContext';
import Home from './locales/ru/components/Home';
import AnimeDetails from './locales/ru/components/AnimeDetails';
import AnimePage from './locales/ru/components/AnimePage';
import NavBar from './locales/ru/components/NavBar';
import Footer from './locales/ru/components/Footer';
import DMCA from './locales/ru/components/DMCA';
import Contact from './locales/ru/components/Contact';
import FilterPage from './locales/ru/components/FilterPage';
import UpcomingAnimePage from './locales/ru/components/UpcomingAnimePage';
import TopAnime from './locales/ru/components/TopAnime';
import TopAnimeUA from './locales/ua/components/TopAnime';
import TermsOfService from './locales/ru/components/TermsOfService';
import HomeUA from './locales/ua/components/Home';
import HomeRU from './locales/ru/components/Home';

import './style.css';
import SignUp from './locales/ru/SignUp';
import Login from './locales/ru/Login';
import Profile from './locales/ru/Profile';
import VerifyEmail from './locales/ru/VerifyEmail';
import MyListPage from './locales/ru/components/MyListPage';

function App() {
  return (
    <AnimeProvider>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ru/" element={<HomeRU />} />
            <Route path="/ua/" element={<HomeUA />} />
            <Route path="/details/:id" element={<AnimeDetails />} />
            <Route path="/anime/:id" element={<AnimePage />} />
            <Route path="/dmca" element={<DMCA />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/genres" element={<FilterPage />} />
            <Route path="/upcoming" element={<UpcomingAnimePage />} />
            <Route path="/top-100" element={<TopAnime />} />
            <Route path="/ua/top-100" element={<TopAnimeUA />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/verify" element={<VerifyEmail />} />
            <Route path="/my-list" element={<MyListPage />} />

          </Routes>
          <Footer />
        </Router>
    </AnimeProvider>
  );
}

export default App;
