import React, { useState, useEffect } from 'react';
import { request, gql } from 'graphql-request';
import { Link } from 'react-router-dom';
import tv from "../../../img/tv.svg";
import tv_hover from "../../../img/tv_hover.svg";
import '../../../style.css';

const endpoint = 'https://shikimori.one/api/graphql';
const kodikApiKey = '6c29a4ee6eb1f841b920f0ff8814ef24';

const cleanDescription = (description) => {
  if (!description) return '';
  return description.replace(/\[.*?\]/g, '').replace(/[\p{Script=Han}]/gu, '');
};

const fetchKodikDescription = async (shikimoriId) => {
  if (!shikimoriId) return null;
  try {
    const response = await fetch(`https://kodikapi.com/search?token=${kodikApiKey}&shikimori_id=${encodeURIComponent(shikimoriId)}&with_episodes=true&with_material_data=true`);
    const data = await response.json();
    const video = data.results.find(result => result.material_data && result.episodes && result.episodes.length > 0);
    return video ? video.material_data.description || '' : null;
  } catch (error) {
    console.error('Error fetching Kodik description:', error);
    return null;
  }
};

const GENRES = [
  { id: 27, name: 'Сёнен' },
  { id: 25, name: 'Сёдзё' },
  { id: 42, name: 'Сэйнэн' },
  { id: 43, name: 'Дзёсей' },
  { id: 15, name: 'Детское' },
  { id: 1, name: 'Экшен' },
  { id: 5, name: 'Авангард' },
  { id: 2, name: 'Приключения' },
  { id: 4, name: 'Комедия' },
  { id: 9, name: 'Этти' },
  { id: 10, name: 'Фэнтези' },
  { id: 14, name: 'Ужасы' },
  { id: 36, name: 'Повседневность' },
  { id: 117, name: 'Триллер' },
  { id: 7, name: 'Тайна' },
  { id: 22, name: 'Романтика' },
  { id: 543, name: 'Гурман' },
  { id: 129, name: 'Сёдзё-ай' },
  { id: 133, name: 'Сёнен-ай' },
  { id: 30, name: 'Спорт' },
  { id: 37, name: 'Сверхъестественное' },
  { id: 8, name: 'Драма' },
  { id: 24, name: 'Фантастика' },
  { id: 125, name: 'Реверс-гарем' },
  { id: 130, name: 'Исэкай' },
  { id: 134, name: 'Забота о детях' },
  { id: 20, name: 'Пародия' },
  { id: 142, name: 'Исполнительское искусство' },
  { id: 148, name: 'Питомцы' },
  { id: 135, name: 'Магическая смена пола' },
  { id: 143, name: 'Антропоморфизм' },
  { id: 102, name: 'Командный спорт' },
  { id: 151, name: 'Романтический подтекст' },
  { id: 107, name: 'Любовный многоугольник' },
  { id: 31, name: 'Супер сила' },
  { id: 38, name: 'Военное' },
  { id: 32, name: 'Вампиры' },
  { id: 145, name: 'Идолы (Жен.)' },
  { id: 40, name: 'Психологическое' },
  { id: 141, name: 'Выживание' },
  { id: 106, name: 'Реинкарнация' },
  { id: 144, name: 'Кроссдрессинг' },
  { id: 119, name: 'CGDCT' },
  { id: 147, name: 'Медицина' },
  { id: 17, name: 'Боевые искусства' },
  { id: 21, name: 'Самураи' },
  { id: 23, name: 'Школа' },
  { id: 29, name: 'Космос' },
  { id: 35, name: 'Гарем' },
  { id: 18, name: 'Меха' },
  { id: 13, name: 'Исторический' },
  { id: 3, name: 'Гонки' },
  { id: 124, name: 'Махо-сёдзё' },
  { id: 150, name: 'Идолы (Муж.)' },
  { id: 103, name: 'Видеоигры' },
  { id: 149, name: 'Образовательное' },
  { id: 139, name: 'Работа' },
  { id: 136, name: 'Шоу-бизнес' },
  { id: 114, name: 'Удостоено наград' },
  { id: 105, name: 'Жестокость' },
  { id: 140, name: 'Иясикэй' },
  { id: 19, name: 'Музыка' },
  { id: 112, name: 'Гэг-юмор' },
  { id: 146, name: 'Игра с высокими ставками' },
  { id: 6, name: 'Мифология' },
  { id: 118, name: 'Спортивные единоборства' },
  { id: 137, name: 'Культура отаку' },
  { id: 111, name: 'Путешествие во времени' },
  { id: 104, name: 'Взрослые персонажи' },
  { id: 39, name: 'Детектив' },
  { id: 11, name: 'Стратегические игры' },
  { id: 108, name: 'Изобразительное искусство' },
  { id: 138, name: 'Организованная преступность' },
  { id: 131, name: 'Хулиганы' }
];

const YEARS = Array.from(new Array(30), (val, index) => (2024 - index).toString());

const SEASONS = [
  { value: 'winter', label: 'Зима' },
  { value: 'spring', label: 'Весна' },
  { value: 'summer', label: 'Лето' },
  { value: 'fall', label: 'Осень' },
];

const SORT_OPTIONS = [
  { value: '', label: 'Все' },
  { value: 'popularity', label: 'Популярность' },
  { value: 'ranked', label: 'Рейтинг' },
];

function GenreFilterPage() {
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedSeason, setSelectedSeason] = useState('');
  const [selectedSort, setSelectedSort] = useState('');
  const [allAnime, setAllAnime] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handleGenreChange = (genreId) => {
    setSelectedGenres(prevGenres =>
      prevGenres.includes(genreId)
        ? prevGenres.filter(id => id !== genreId)
        : [...prevGenres, genreId]
    );
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

  const handleSortChange = (event) => {
    setSelectedSort(event.target.value);
  };

  const formatFilterString = () => {
    let filterString = '';

    if (selectedSeason) {
      filterString += selectedSeason;
    }

    if (selectedYear) {
      if (filterString) filterString += '_';
      filterString += selectedYear;
    }
    return filterString;
  };

  const fetchAnime = async (page = 1) => {
    setIsLoading(true);
    setError(null);

    const genreString = selectedGenres.join(',');
    const seasonYearString = formatFilterString();
    const order = selectedSort ? `order: ${selectedSort}` : '';

    const query = gql`
      {
        animes(limit: 40, page: ${page}, genre: "${genreString}", season: "${seasonYearString}" ${order ? `, ${order}` : ''}) {
          id
          russian
          description
          poster {
            mainUrl
          }
          genres {
            russian
          }
        }
      }
    `;

    try {
      const data = await request(endpoint, query);
      const validAnimes = await Promise.all(data.animes.map(async (anime) => {
        const kodikDescription = await fetchKodikDescription(anime.id);
        const description = cleanDescription(anime.description) || kodikDescription;
        return description ? { ...anime, description } : null;
      }));

      const filteredAnimes = validAnimes.filter(anime => anime !== null);

      if (filteredAnimes.length === 0) {
        setHasMore(false);
      } else {
        setAllAnime(prevAnime => page === 1 ? filteredAnimes : [...prevAnime, ...filteredAnimes]);
      }
    } catch (error) {
      console.error('Ошибка при запросе аниме:', error.response || error);
      setError('Произошла ошибка при запросе аниме.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1); // Сбрасываем на первую страницу при изменении фильтров
    setAllAnime([]);   // Очищаем список аниме
    setHasMore(true);  // Сбрасываем состояние наличия дополнительных данных
    fetchAnime();      // Заново загружаем аниме с новыми фильтрами
  }, [selectedGenres, selectedSeason, selectedYear, selectedSort]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchAnime(currentPage); // Загружаем новые страницы с учетом фильтров
    }
  }, [currentPage]);

  const handleScroll = () => {
    const scrollTop = window.scrollY || window.pageYOffset;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const bodyHeight = document.body.scrollHeight || document.documentElement.scrollHeight;

    if (windowHeight + scrollTop >= bodyHeight / 2 && hasMore && !isLoading) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, isLoading]);

  return (
    <div className='container'>
      <div className='wrap-genres-anime'>
        <h1 className='genres-title'>Жанры</h1>
      <div className='filter-container'>
  {/* Фильтр по жанрам */}
  {GENRES.map((genre) => (
    <div key={genre.id}>
      <input
        className='checkbox'
        type="checkbox"
        id={`genre-${genre.id}`} // добавляем id для каждого чекбокса
        value={genre.id}
        onChange={() => handleGenreChange(genre.id)}
        checked={selectedGenres.includes(genre.id)}
      />
      <label htmlFor={`genre-${genre.id}`}> {/* связываем label с чекбоксом */}
        {genre.name}
      </label>
    </div>
  ))}
</div>
          <div className='wrap-sort'>
                      {/* Фильтр по годам */}
          <label className='label-sort'>
            <p>Год:</p>
            <div className='select-wrapper'>
            <select value={selectedYear} onChange={handleYearChange}>
              <option value="">Все года</option>
              {YEARS.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            </div>
          </label>

          {/* Фильтр по сезонам */}
          <label className='label-sort'>
            <p>Сезон:</p>
            <div className='select-wrapper'>
            <select value={selectedSeason} onChange={handleSeasonChange}>
              <option value="">Все сезоны</option>
              {SEASONS.map((season) => (
                <option key={season.value} value={season.value}>{season.label}</option>
              ))}
            </select>
            </div>
          </label>

          {/* Сортировка по популярности или рейтингу */}
          <label className='label-sort'>
            <p>Сортировать по:</p>
            <div className='select-wrapper'>
            <select value={selectedSort} onChange={handleSortChange}>
              {SORT_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            </div>
          </label>
          </div>
        <section className='s2'>
          <div className='bg'>
            <div className='wrap-grid-anime'>
              {allAnime.map((anime, index) => (
                <div className='wrap-anime' key={`${anime.id}-${index}`}>
                  <Link to={`/anime/${anime.id}`}>
                    <img className='img-anime' src={anime.poster.mainUrl} alt={anime.russian} />
                    <h3>{anime.russian}</h3>
                    <div className='hover-anime'>
                      <div>
                        <h3>{anime.russian}</h3>
                        <p>{anime.score}</p>
                        <p className='anime-description'>{cleanDescription(anime.description)}</p>
                      </div>
                      <div className="slide-buttons item-btn">
                        <div to={`/anime/${anime.id}`} className="watch-now">
                          Watch Now
                          <div className='wrap-img-btn'>
                            <img className="tv" src={tv} alt="Button Watch Now" />
                            <img className='tv_hover' src={tv_hover} alt="Button Watch Now Hover" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            {/*            {isLoading && <div>Загрузка...</div>}
              {error && <div>{error}</div>}
              {!hasMore && <div className='end-anime'>Нет больше аниме для загрузки</div>} */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default GenreFilterPage;
