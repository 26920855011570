import { Link } from "react-router-dom";
import logo from "../../../img/logo_animexcross 1.svg";
function Footer(){
    return(
        <>
        <section className="footer">
        <div className="container">
            <div className="wrap-footer">
                <div className="wrap-logo-and-content">
                    <img src={logo} alt="Logo" />
                    <div>
                        <p>Copyright © 2024 AnimexCross. All Rights Reserved</p>
                        <p>Disclaimer: This site AnimexCross does not store any files on its server.
                             All contents are provided by non-affiliated third parties.
                        </p>
                    </div>
                </div>
                <div className="wrap-footer-link">
                    <Link to="/genres">Жанры</Link>
                    <Link to="/contact">Контакт</Link>
                    <Link to="/terms-of-service">Соглашение</Link>
                    <Link to="/dmca">DMCA</Link>
                </div>
            </div>
        </div>
        </section>
        </>
    );
}

export default Footer;
