import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAk6H3vafMZmpfFIZMsb5QaNfDtN3RiNEI",
  authDomain: "animexcross-dd423.firebaseapp.com",
  projectId: "animexcross-dd423",
  storageBucket: "animexcross-dd423.firebasestorage.app",
  messagingSenderId: "276569645224",
  appId: "1:276569645224:web:6a059f08d9744a299fa29d",
  measurementId: "G-KZW21JYZTY"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
export { auth, db };