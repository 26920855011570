import { Link } from "react-router-dom";
import { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; // Импорт Firestore методов
import { auth, db } from "../../firebase"; // Импорт Firebase

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [copyPassword, setCopyPassword] = useState("");
  const [nameUser, setUserName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false); // Флаг для отображения сообщения об отправке письма

  const register = async (e) => {
    e.preventDefault();

    if (copyPassword !== password) {
      setError("Пароли не совпадают");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      // Создание пользователя
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Обновляем профиль пользователя
      await updateProfile(user, {
        displayName: nameUser,
      });

      // Сохраняем данные пользователя в Firestore
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, {
        email: user.email,
        nickname: nameUser,
        createdAt: new Date().toISOString(), // Дата создания
      });

      // Отправляем письмо для активации email
      await sendEmailVerification(user);

      // Устанавливаем флаг, чтобы показать сообщение о том, что письмо отправлено
      setIsEmailSent(true);

      // Сброс формы
      setEmail("");
      setCopyPassword("");
      setPassword("");
      setUserName("");
    } catch (error) {
      console.error("Ошибка регистрации:", error);
      if (error.code === "auth/email-already-in-use") {
        setError("Этот email уже зарегистрирован.");
      } else if (error.code === "auth/invalid-email") {
        setError("Введите корректный email.");
      } else if (error.code === "auth/weak-password") {
        setError("Пароль должен быть не менее 6 символов.");
      } else {
        setError("Ошибка регистрации. Попробуйте снова.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="s1 center-block">
        <h2>Создать аккаунт</h2>
        <form onSubmit={register} className="wrap-form">
          <label>
            <span className="wrap-text-label">Ваш E-Mail <span className="required">*</span>:</span>
            <input
              placeholder="Введите ваш Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
            />
          </label>
          <label>
            <span className="wrap-text-label">Ваш Никнейм <span className="required">*</span>:</span>
            <input
              placeholder="Введите ваш Никнейм"
              value={nameUser}
              onChange={(e) => setUserName(e.target.value)}
              type="text"
              required
            />
          </label>
          <label>
            <span className="wrap-text-label">Ваш Пароль <span className="required">*</span>:</span>
            <input
              placeholder="Введите Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              required
            />
          </label>
          <label>
            <span className="wrap-text-label">Повторите пароль <span className="required">*</span>:</span>
            <input
              placeholder="Снова введите пароль для проверки"
              value={copyPassword}
              onChange={(e) => setCopyPassword(e.target.value)}
              type="password"
              required
            />
          </label>

          <button className="watch-now center" type="submit" disabled={isLoading}>
            {isLoading ? "Создание..." : "Создать"}
          </button>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {isEmailSent && (
            <p style={{ color: "green" }}>
              Письмо с подтверждением отправлено на ваш email! Пожалуйста, проверьте вашу почту.
            </p>
          )}
        </form>

        <p>
          <Link to="/login">Уже есть аккаунт? Войти</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
