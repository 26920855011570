import React, { useState, useEffect } from 'react';
import { auth, db } from '../../../firebase';
import { collection, addDoc, query, orderBy, onSnapshot, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../../firebase';

const CommentSection = ({ animeId }) => {
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [replyToCommentId, setReplyToCommentId] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, 'anime', animeId, 'comments'), orderBy('createdAt', 'desc')),
      (snapshot) => {
        const fetchedComments = snapshot.docs.map(doc => doc.data());
        setComments(fetchedComments);
      }
    );

    return () => unsubscribe();
  }, [animeId]);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);
      // Fetch user's avatar if it exists
      if (currentUser.photoURL) {
        setAvatar(currentUser.photoURL);
      }
    }
  }, []);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (!commentText.trim()) return;

    try {
      const commentRef = collection(db, 'anime', animeId, 'comments');
      await addDoc(commentRef, {
        text: commentText,
        userId: user.uid,
        username: user.displayName || 'Без имени',
        avatar: avatar || 'https://example.com/default-avatar.png',
        createdAt: new Date(),
        likes: [], // Initialize an empty array for likes
        replies: [] // Initialize an empty array for replies
      });
      setCommentText('');
    } catch (error) {
      console.error('Ошибка при добавлении комментария:', error);
    }
  };

  const handleLikeComment = async (commentId) => {
    try {
      const commentRef = doc(db, 'anime', animeId, 'comments', commentId);
      const commentSnapshot = await commentRef.get();
      const commentData = commentSnapshot.data();

      if (!commentData) return;

      // Ensure likes array exists
      const likes = commentData.likes || [];

      // Check if the user already liked this comment
      const hasLiked = likes.includes(user.uid);
      const updatedLikes = hasLiked
        ? likes.filter((uid) => uid !== user.uid) // Remove the user from likes
        : [...likes, user.uid]; // Add the user to likes

      await updateDoc(commentRef, { likes: updatedLikes });
    } catch (error) {
      console.error('Ошибка при лайке комментария:', error);
    }
  };

  const handleReplySubmit = async (e, commentId) => {
    e.preventDefault();

    if (!replyText.trim()) return;

    try {
      const commentRef = doc(db, 'anime', animeId, 'comments', commentId);

      // Ensure replies array exists
      const commentSnapshot = await commentRef.get();
      const commentData = commentSnapshot.data();
      const replies = commentData.replies || []; // Default to an empty array if no replies exist

      await updateDoc(commentRef, {
        replies: [...replies, {
          text: replyText,
          userId: user.uid,
          username: user.displayName || 'Без имени',
          avatar: avatar || 'https://example.com/default-avatar.png',
          createdAt: new Date()
        }]
      });

      setReplyText('');
      setReplyToCommentId(null); // Reset the reply
    } catch (error) {
      console.error('Ошибка при добавлении ответа:', error);
    }
  };

  return (
    <div className='container comment-container'>
      <h3>Комментарии</h3>
      {user ? (
        <form className='comment-form' onSubmit={handleCommentSubmit}>
          <textarea
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            placeholder="Напишите ваш комментарий..."
          />
          <button className='watch-now' type="submit">Отправить</button>
        </form>
      ) : (
        <p>Чтобы оставить комментарий, пожалуйста, <a href="/login">зарегистрируйтесь</a>.</p>
      )}

      <div className='comments-list'>
        {comments.map((comment) => (
          <div key={comment.createdAt} className='comment-item'>
            <div className='comment-avatar'>
              <img src={comment.avatar} alt={comment.username} />
            </div>
            <div className='comment-body'>
              <div className="comment-header">
                <p className='comment-author'>{comment.username}</p>
                <p className='comment-date'>
                  {new Date(comment.createdAt.seconds * 1000).toLocaleDateString()} {/* Formatting the date */}
                </p>
              </div>
              <p>{comment.text}</p>

              {/* Like Button */}
              <div className="like-button" onClick={() => handleLikeComment(comment.id)}>
                <span
                  className={`like-heart ${comment.likes.includes(user?.uid) ? 'liked' : ''}`}
                  role="img"
                  aria-label="like"
                >
                  ❤️
                </span>
                <span>{comment.likes.length}</span> {/* Display the number of likes */}
              </div>

              {/* Reply Button */}
              <button onClick={() => setReplyToCommentId(comment.id)}>
                Ответить
              </button>

              {/* Replies */}
              {comment.replies && comment.replies.length > 0 && (
                <div className="replies">
                  {comment.replies.map((reply, index) => (
                    <div key={index} className="reply-item">
                      <div className="reply-avatar">
                        <img src={reply.avatar} alt={reply.username} />
                      </div>
                      <div className="reply-body">
                        <p className="reply-author">{reply.username}</p>
                        <p>{reply.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* Reply Form */}
              {replyToCommentId === comment.id && (
                <form onSubmit={(e) => handleReplySubmit(e, comment.id)} className="reply-form">
                  <textarea
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    placeholder="Напишите ваш ответ..."
                  />
                  <button type="submit">Ответить</button>
                </form>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentSection;
