import React, { useEffect, useState } from 'react';
import { request, gql } from 'graphql-request';
import { Link } from 'react-router-dom';
import tv from "../../../img/tv.svg";
import tv_hover from "../../../img/tv_hover.svg";
import '../../../style.css';

const endpoint = 'https://shikimori.one/api/graphql';

const cleanDescription = (description) => {
  if (!description) return '';
  return description.replace(/\[.*?\]/g, '').replace(/[\p{Script=Han}]/gu, '');
};

const TopAnimePage = () => {
  const [topAnime, setTopAnime] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTopAnime = async (page) => {
    setIsLoading(true);

    const query = gql`
      {
        animes(limit: 50, page: ${page}, order: ranked_shiki) {
          id
          name
          russian
          japanese
          score
          status
          episodes
          season
          description
          airedOn { year month day }
          poster { mainUrl }
          genres { name russian }
          studios { name }
        }
      }
    `;

    try {
      const data = await request(endpoint, query);
      if (data.animes.length < 50 || currentPage === 2) {
        setHasMore(false);
      }
      setTopAnime(prevAnime => [...prevAnime, ...data.animes]);
    } catch (error) {
      console.error('Ошибка при запросе аниме:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentPage <= 2) {
      fetchTopAnime(currentPage);
    }
  }, [currentPage]);

  const handleScroll = () => {
    const scrollTop = window.scrollY || window.pageYOffset;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const bodyHeight = document.body.scrollHeight || document.documentElement.scrollHeight;

    if (windowHeight + scrollTop >= bodyHeight / 2 && hasMore && !isLoading) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, isLoading]);

  return (
    <div>
      <section className='s2 top-s'>
        <div className='bg'>
          <div className='container'>
            <div className='wrap-top-anime'>
              <h1 className='top-anime-title'>Топ аниме по рейтингу</h1>
              <div className='wrap-items-top'>
                {topAnime.map((anime) => (
                  <div className='wrap-anime' key={anime.id}>
                    <Link to={`/anime/${anime.id}`}>
                      <img className='img-anime' src={anime.poster.mainUrl} alt={anime.russian} />
                      <h3>{anime.russian}</h3>
                      <div className='hover-anime'>
                        <div>
                          <h3>{anime.russian}</h3>
                          <p>{anime.score}</p>
                          <p className='anime-description'>{cleanDescription(anime.description) || 'Нет описания'}</p>
                        </div>
                        <div className="slide-buttons item-btn">
                          <div to={`/anime/${anime.id}`} className="watch-now">
                            Watch Now
                            <div className='wrap-img-btn'>
                              <img className="tv" src={tv} alt="Button Watch Now" />
                              <img className='tv_hover' src={tv_hover} alt="Button Watch Now Hover" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
                {isLoading && <div>Загрузка...</div>}
                {!hasMore && <div>Нет больше аниме для загрузки</div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopAnimePage;
