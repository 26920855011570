import React, { useState, useEffect } from 'react';
import { auth, db } from "../../../firebase"; // Импорт Firebase
import { collection, query, where, getDocs } from "firebase/firestore";
import { Link } from 'react-router-dom';

const MyListPage = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchFavorites = async () => {
      const user = auth.currentUser;

      if (!user) {
        setError('Вы должны быть авторизованы для просмотра избранных.');
        setLoading(false);
        return;
      }

      try {
        const q = query(
          collection(db, 'users', user.uid, 'favorites')
        );
        const querySnapshot = await getDocs(q);
        const fetchedFavorites = querySnapshot.docs.map(doc => doc.data());
        setFavorites(fetchedFavorites);
        setLoading(false);
      } catch (err) {
        setError('Ошибка при загрузке избранных аниме.');
        setLoading(false);
      }
    };

    fetchFavorites();
  }, []);

  if (loading) return <p>Загрузка...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container pt-160px">
      <h2>Мой список избранного</h2>
      {favorites.length === 0 ? (
        <p>У вас нет избранных аниме.</p>
      ) : (
        <div className="favorites-list">
          {favorites.map((favorite, index) => (
            <div key={index} className="favorite-item">
              <Link to={`/anime/${favorite.animeId}`}>
                <img src={favorite.poster} alt={favorite.title} />
                <p>{favorite.title}</p>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyListPage;
