import React from 'react';
import MostPopular from './MostPopular';
import MostFavorite from './MostFavorite';
import GenresList from './Movie';
import Slider from './Slider';
import '../../../style.css';
import MostTrend from './MostTrend';
import LastWatchedAnime from './LastWatchedAnime';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <div className="homepage">
            <Helmet>
        <title>Animexcross - Онлайн аниме бесплатно</title>
        <meta
          name="description"
          content="Animexcross - cмотрите аниме онлайн бесплатно и без ограничений. Лучшие релизы и топовые аниме в одном месте!"
        />
      </Helmet>
      <div className="content">
        <h1 className='title-home'>Animexcross - онлайн аниме для бесплатного и приятного просмотра</h1>
        <Slider /> 
        <MostTrend />
        <LastWatchedAnime />
        <MostPopular />
        <MostFavorite />
        <GenresList />
      </div>
    </div>
  );
};

export default Home;
