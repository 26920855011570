import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { updateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("info"); // Управление табами
  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState(""); // Для ввода текущего пароля
  const [history, setHistory] = useState([]);
  
  const db = getFirestore();

  useEffect(() => {
    const fetchAvatars = async () => {
      const storage = getStorage();
      const avatarRef = ref(storage, "avatars/");
      try {
        const res = await listAll(avatarRef);
        const avatarURLs = await Promise.all(
          res.items.map((item) => getDownloadURL(item))
        );
        setAvatars(avatarURLs);
      } catch (err) {
        console.error("Ошибка загрузки аватарок:", err);
        setError("Не удалось загрузить аватарки.");
      }
    };

    const fetchHistory = async () => {
      if (auth.currentUser) {
        const historyRef = doc(db, "users", auth.currentUser.uid);
        try {
          const historyDoc = await getDoc(historyRef);
          if (historyDoc.exists()) {
            setHistory(historyDoc.data().history || []);
          } else {
            console.log("История отсутствует");
            setHistory([]);
          }
        } catch (err) {
          console.error("Ошибка загрузки истории:", err);
          setError("Не удалось загрузить историю просмотров.");
        }
      }
    };

    fetchAvatars();
    fetchHistory();
  }, [auth.currentUser, db]);

  const handleAvatarSelect = async (avatar) => {
    if (!auth.currentUser) {
      setError("Пользователь не авторизован");
      return;
    }

    try {
      await updateProfile(auth.currentUser, {
        photoURL: avatar,
      });

      setSelectedAvatar(avatar);
      setError("");
      console.log("Аватарка обновлена:", avatar);

      // Обновляем пользователя вручную
      await auth.currentUser.reload();
    } catch (err) {
      console.error("Ошибка обновления аватарки:", err);
      setError("Не удалось обновить аватарку.");
    }
  };

  const handleChangePassword = async () => {
    if (!auth.currentUser) {
      setError("Пользователь не авторизован");
      return;
    }

    if (password !== confirmPassword) {
      setError("Пароли не совпадают");
      return;
    }

    try {
      const user = auth.currentUser;
      
      // Проверка на введение текущего пароля
      if (!currentPassword) {
        setError("Введите текущий пароль для подтверждения изменений.");
        return;
      }

      // Создание учетных данных для аутентификации
      const credentials = EmailAuthProvider.credential(user.email, currentPassword);

      // Повторная аутентификация пользователя
      await reauthenticateWithCredential(user, credentials);
      
      // Смена пароля
      await updatePassword(user, password);

      setError("");
      console.log("Пароль обновлен");
      setPassword("");
      setConfirmPassword(""); // Сброс пароля и подтверждения пароля
      setCurrentPassword(""); // Сброс текущего пароля
    } catch (err) {
      console.error("Ошибка смены пароля:", err);
      setError("Не удалось сменить пароль.");
    }
  };

  return (
    <div className="profile">
      {auth.currentUser ? (
        <div>
          <div className="tabs">
            <button onClick={() => setActiveTab("info")} className={activeTab === "info" ? "active" : ""}>
              Информация
            </button>
            <button onClick={() => setActiveTab("password")} className={activeTab === "password" ? "active" : ""}>
              Смена пароля
            </button>
            <button onClick={() => setActiveTab("history")} className={activeTab === "history" ? "active" : ""}>
              История
            </button>
          </div>

          <div className="tab-content wrap-form-password">
            {activeTab === "info" && (
              <div>
                <p>Имя пользователя: {auth.currentUser.displayName || "Пользователь"}</p>
                <p>Email: {auth.currentUser.email}</p>
                <h3>Выберите аватарку:</h3>
                <div className="avatar-selection">
                  {avatars.map((avatar, index) => (
                    <img
                      key={index}
                      src={avatar}
                      alt={`Avatar ${index + 1}`}
                      className={`avatar ${selectedAvatar === avatar ? "selected" : ""}`}
                      onClick={() => handleAvatarSelect(avatar)}
                    />
                  ))}
                </div>
              </div>
            )}

            {activeTab === "password" && (
              <div className="change-passw">
                <h3>Смена пароля</h3>
                <input
                  type="password"
                  placeholder="Введите текущий пароль"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Введите новый пароль"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Подтвердите новый пароль"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button className="watch-now center-btn" onClick={handleChangePassword}>Сменить пароль</button>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            )}

            {activeTab === "history" && (
              <div className="history-tab">
                <h3>История просмотров</h3>
                {history.length > 0 ? (
                  <ul>
                    {history.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p>История просмотров пуста.</p>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <p>Пожалуйста, войдите в систему, чтобы управлять профилем.</p>
      )}
    </div>
  );
};

export default Profile;
