import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request, gql } from 'graphql-request';
import VideoPlayer from './VideoPlayer';

const shikimoriEndpoint = 'https://shikimori.one/api/graphql';
const kodikApiKey = '6c29a4ee6eb1f841b920f0ff8814ef24';

const AnimeDetails = () => {
  const { id } = useParams();  // Получаем shikimori_id из URL
  const [anime, setAnime] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Функция для очистки описания
  const cleanDescription = (description) => {
    if (!description) return '';

    // Удаляем текст в квадратных скобках и японские символы
    return description.replace(/\[.*?\]/g, '').replace(/[\p{Script=Han}]/gu, '');
  };

  useEffect(() => {
    const fetchAnimeData = async () => {
      const query = gql`
        {
          animes(limit: 21, kind: "tv", order: popularity) {
            id
            russian
            description
            poster {
              mainUrl
            }
          }
        }
      `;

      try {
        const data = await request(shikimoriEndpoint, query);
        const fetchedAnime = data.animes.find(anime => anime.id.toString() === id);

        if (fetchedAnime) {
          setAnime(fetchedAnime);

          const kodikVideoUrl = await fetchKodikVideo(id);
          setVideoUrl(kodikVideoUrl);
        } else {
          throw new Error('Аниме не найдено.');
        }

        setLoading(false);
      } catch (error) {
        setError('Ошибка при запросе аниме: ' + error.message);
        setLoading(false);
      }
    };

    const fetchKodikVideo = async (shikimoriId) => {
      try {
        const response = await fetch(`https://kodikapi.com/search?shikimori_id=${encodeURIComponent(shikimoriId)}&token=${kodikApiKey}&with_episodes="true"`);
        const data = await response.json();
        console.log('Kodik API Response:', data);

        const video = data.results.find(result => result.shikimori_id === shikimoriId);
        const videoLink = video ? `https:${video.link}` : null;
        return videoLink;
      } catch (error) {
        console.error('Ошибка при запросе Kodik API:', error);
        return null;
      }
    };

    fetchAnimeData();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!anime) return <p>Аниме не найдено.</p>;

  return (
    <div>
      <h1>{anime.russian}</h1>
      {anime.poster && <img src={anime.poster.mainUrl} alt={anime.russian} />}
      <p>{cleanDescription(anime.description)}</p>
      {videoUrl ? (
        <VideoPlayer
          title={anime.russian}
          videoUrl={videoUrl}
        />
      ) : (
        <p>Видео не найдено или загрузка данных...</p>
      )}
    </div>
  );
};

export default AnimeDetails;
