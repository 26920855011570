import React, { useState, useEffect } from 'react';
import { request, gql } from 'graphql-request';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import tv from "../../../img/tv.svg"
import tv_hover from "../../../img/tv_hover.svg"
import '../../../style.css'; 

const endpoint = 'https://shikimori.one/api/graphql';

const cleanDescription = (description) => {
  if (!description) return '';
  return description.replace(/\[.*?\]/g, '').replace(/[\p{Script=Han}]/gu, '');
};
const MostPopular = () => {
  const [animes, setAnimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAnimeData = async (retryCount = 3) => {
    const query = gql`
      {
        animes(limit: 30, season: "2024", kind: "tv", order: popularity) {
          id
          name
          russian
          score
          description
          poster {
            mainUrl
          }
        }
      }
    `;

    try {
      const data = await request(endpoint, query);
      setAnimes(data.animes);
    } catch (error) {
      if (error.response?.status === 429 && retryCount > 0) {
        console.warn('Лимит запросов превышен, повторная попытка через 1 секунду...');
        await new Promise(res => setTimeout(res, 1000));
        await fetchAnimeData(retryCount - 1);
      } else {
        setError('Ошибка при запросе популярных аниме: ' + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnimeData();
  }, []);

  if (error) return <p>{error}</p>;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className='s2'>
      <div className='bg'>
        <div className='container'>
          <h2>Популярные аниме</h2>
          <Slider {...settings} className="most-popular-slider">
            {animes.map((anime) => (
              <div className='wrap-anime' key={anime.id}>
                <Link to={`/anime/${anime.id}`}>
                  <img className='img-anime' src={anime.poster.mainUrl} alt={anime.russian} />
                  <h3>{anime.russian}</h3>
                  <div className='hover-anime'>
                    <div>
                    <h3>{anime.russian}</h3>
                    <p>{anime.score}</p>
                    <p className='anime-description'>{cleanDescription(anime.description)}</p>
                    </div>
                    <div className="slide-buttons item-btn">
                    <div to={`/anime/${anime.id}`} className="watch-now">
                    Watch Now
                    <div className='wrap-img-btn'>
                      <img className="tv" src={tv} alt="Button Watch Now"/>
                      <img className='tv_hover' src={tv_hover} alt="Button Watch Now Hover"/>
                      </div>
                    </div>
                  </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

const CustomArrow = ({ direction, onClick }) => (
  <div
    className={`custom-arrow custom-arrow-${direction}`}
    onClick={onClick}
  >
    <span className={`arrow-${direction}`} />
  </div>
);

export default MostPopular;
