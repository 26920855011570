import React, { useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";
import userStandart from "../../img/user.svg";

const UserMenu = () => {
  const [user, setUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setMenuOpen(false);
    } catch (error) {
      console.error("Ошибка при выходе из системы:", error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="user">
      {user ? (
        <div className="dropdown">
          <img
            src={user.photoURL || userStandart}
            alt="user avatar"
            className="user-avatar"
            onClick={toggleMenu}
          />
          {menuOpen && (
            <div className="dropdown-content">
              <p>{user.displayName || "Пользователь"}</p>
              <Link to="/profile" onClick={closeMenu}>
                Мой профиль
              </Link>
              {/*
              <Link to="/my-list" onClick={closeMenu}>
                Мой список
              </Link>
              */}
              <button className="watch-now center-btn" onClick={handleLogout}>Выйти</button>
            </div>
          )}
        </div>
      ) : (
        <div className="auth-options">
          <Link to="/login">Вход</Link>
          <Link to="/sign-up">Регистрация</Link>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
