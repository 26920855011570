import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { applyActionCode } from "firebase/auth";
import { auth } from "../../firebase";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    const oobCode = searchParams.get("oobCode");

    if (oobCode) {
      applyActionCode(auth, oobCode)
        .then(() => {
          setStatus("success");
        })
        .catch(() => {
          setStatus("error");
        });
    } else {
      setStatus("invalid");
    }
  }, [searchParams]);

  return (
    <div>
      <h1>Страница подтверждения</h1>
      <p>Текущий статус: {status}</p>
      {status === "loading" && <p>Проверка вашего кода...</p>}
      {status === "success" && <p>Ваш email успешно подтвержден. Спасибо!</p>}
      {status === "error" && (
        <p>Ошибка при подтверждении. Возможно, ссылка устарела или недействительна.</p>
      )}
      {status === "invalid" && <p>Некорректный запрос. Проверьте ссылку.</p>}
    </div>
  );
  
};

export default VerifyEmail;
