import React from 'react';
import { useLocalStorage } from 'react-use'; // Хук useLocalStorage
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import tv from "../../../img/tv.svg";
import tv_hover from "../../../img/tv_hover.svg";
import '../../../style.css';

const LastWatchedAnime = () => {
  // Используем useLocalStorage для хранения последних просмотренных аниме
  const [lastWatchedAnimes, setLastWatchedAnimes] = useLocalStorage('lastWatchedAnime', []);

  // Функция для обновления списка последних просмотренных аниме
  const handleWatchAnime = (anime) => {
    setLastWatchedAnimes((prevAnimes) => {
      const updatedAnimes = prevAnimes.filter(item => item.id !== anime.id);
      updatedAnimes.unshift(anime);

      return updatedAnimes.slice(0, 10); // Ограничиваем список до 10 элементов
    });
  };

  // Если список пуст, отображаем сообщение
  if (!lastWatchedAnimes || lastWatchedAnimes.length === 0) {
    return <p></p>;
  }

  // Настройки слайдера
  const sliderSettings = {
    dots: false,
    infinite: false, // Отключаем бесконечный скролл
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className='s2'>
      <div className='bg'>
        <div className='container'>
          <h2>Последнее просмотренное аниме</h2>
          <Slider {...sliderSettings} className="last-watched-slider">
            {lastWatchedAnimes.map((anime, index) => (
              <div className='wrap-anime' key={`${anime.id}-${index}`}> {/* Уникальный ключ */}
                <Link
                  to={`/anime/${anime.id}`} // Ссылка на страницу аниме по ID
                  onClick={() => handleWatchAnime(anime)} // Обновляем историю последних просмотренных
                >
                  <img
                    className='img-anime'
                    src={anime.poster || 'https://via.placeholder.com/150'} // Заглушка для постера
                    alt={anime.title || 'Без названия'}
                  />
                  <h3>{anime.title || 'Без названия'}</h3>
                </Link>
                <Link
                  to={`/anime/${anime.id}`} // Ссылка на страницу аниме по ID
                  onClick={() => handleWatchAnime(anime)} // Обновляем историю последних просмотренных
                >
                <div className='hover-anime'>
                  <div>
                    <h3>{anime.title || 'Без названия'}</h3>
                    <p>{anime.watchedAt ? `Просмотрено: ${new Date(anime.watchedAt).toLocaleString()}` : 'Нет информации'}</p>
                  </div>
                  <div className="slide-buttons item-btn">
                    <Link
                      to={`/anime/${anime.id}`} // Ссылка на страницу аниме по ID
                      className="watch-now center-btn"
                      onClick={() => handleWatchAnime(anime)}
                    >
                      Watch Now
                      <div className='wrap-img-btn'>
                        <img className="tv" src={tv} alt="Button Watch Now" />
                        <img className='tv_hover' src={tv_hover} alt="Button Watch Now Hover" />
                      </div>
                    </Link>
                  </div>
                </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

// Компонент для кастомных стрелок слайдера
const CustomArrow = ({ direction, onClick }) => (
  <div
    className={`custom-arrow custom-arrow-${direction}`}
    onClick={onClick}
  >
    <span className={`arrow-${direction}`} />
  </div>
);

export default LastWatchedAnime;
