import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAnime } from "../../../AnimeContext";
import searchIcon from "../../../img/search.svg";
import user from "../../../img/user.svg";
import logo from "../../../img/logo_animexcross 1.svg";
import SearchModal from "./SearchModal";
import "../../../style.css";

function NavBar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { language, setLanguage } = useAnime();
  const navigate = useNavigate();

  const toggleSearchModal = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    navigate(`/${lang}/home`);
    if (lang == 'en') {
      navigate(`/`);
    }
  };

  // Формируем ссылки в зависимости от языка
  const generateLink = (path) => `/${language}${path}`;

  return (
    <>
      <nav>
        <div className="bg-nav">
          <div className="container nav-container">
            <div className="wrap-logo">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <div className='wrap-link'>
                <Link to={generateLink("/genres")}>Жанри</Link>
                <Link to={generateLink("/top-100")}>Топ-100 аніме</Link>
                <Link to={generateLink("/upcoming")}>Очікувані Аніме</Link>
                <Link to={generateLink("/contact")}>Зворотній зв'язок</Link>
              </div>
              <div className="language-selector">
                <select value={language} onChange={(e) => handleLanguageChange(e.target.value)}>
                  <option value="ua">UA</option>
                  <option value="ru">RU</option>
                  <option value="en">EN</option>
                </select>
              </div>
              <div className="wrap-user-icon">
                <div className="search" onClick={toggleSearchModal}>
                  <img src={searchIcon} alt="search" />
                </div>
                <div className="user">
                  <img src={user} alt="user" />
                </div>
                <div className="burger-menu" onClick={toggleMenu}>
                  <div className={`burger-line ${isMenuOpen ? 'open' : ''}`}></div>
                  <div className={`burger-line ${isMenuOpen ? 'open' : ''}`}></div>
                  <div className={`burger-line ${isMenuOpen ? 'open' : ''}`}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {isSearchOpen && <SearchModal onClose={toggleSearchModal} />}
      
      {isMenuOpen && (
        <div className="mobile-menu">
          <Link to={generateLink("/genres")} onClick={toggleMenu}>Жанры</Link>
          <Link to={generateLink("/top-100")} onClick={toggleMenu}>Топ-100 аниме</Link>
          <Link to={generateLink("/upcoming")} onClick={toggleMenu}>Ожидаемые Аниме</Link>
          <Link to={generateLink("/contact")} onClick={toggleMenu}>Контакт</Link>
        </div>
      )}
    </>
  );
}

export default NavBar;
